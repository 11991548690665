<template >
  <div>
    <footer id="footer" class="footer">
      <div class="footer-newsletter">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
              <h4>Monthly Insight</h4>
              <p>Receive our monthly research output.</p>
            </div>
            <div class="col-lg-6">
              <form style="border-radius: 30px;">
                <input
                  
                  placeholder="E-mail address"
                  v-model="email"
                  type="email"
                  name="email"
                /><b-button variant="primary" pill style="width: 130px" @click="subscribe"
                  >Subscribe</b-button
                >
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="footer-top">
        <div class="container">
          <div class="row gy-4">
            <div class="col-lg-3 col-md-6 footer-info">
              
              <p>
               
              </p>
              <div class="social-links mt-3">
                <a href="https://www.facebook.com/FirstConsultEth" class="facebook" style="margin-right: 50px;" target="_blank"><i class="bi bi-facebook footer-icon"></i></a>
                <a href="https://twitter.com/firstconsulteth" class="twitter" style="margin-right: 50px;" target="_blank"><i class="bi bi-twitter footer-icon"></i></a>
                <a href="https://et.linkedin.com/company/first-consult-plc" class="twitter" style="margin-right: 50px;" target="_blank"><i class="bi bi-linkedin footer-icon"></i></a>
              </div>
            </div>

            <div class="col-lg-3 col-6 footer-links">
              <h4>
                Explorer
              </h4>
              <ul>
              <li>
              <a  @click="routeTo('about_us')" class="contactus-link" >About US</a>
              </li>
              <li>
              <a  @click="routeTo('our_work')" class="contactus-link" >Our Work</a>
              </li>
              <li>
              <a  @click="routeTo('knowledge')" class="contactus-link" >Knowledge</a>
              </li>
              <li>
              <a  @click="routeTo('blog')" class="contactus-link" >News</a>
              </li>
              <li>
              <a  @click="routeTo('contact_us')" class="contactus-link" >Get in touch</a>
              </li>
              </ul>
            </div>
              <div class="col-lg-3 col-6 footer-links">
              <h4>
                Location
              </h4>
              Addis Ababa, Ethiopia<br />
              Gabon Street (Meskel Flower Road),<br> Central Printing Press Building
            </div>

            <div
              class="
                col-lg-3 col-md-12
                footer-contact
                text-center text-md-start
              "
            >
              <h4 >
                 Conversation
                

                </h4>
              <p>
                info@firstconsultet.com<br />
                 +251114 401472/73<br />
                 
              </p>
            </div>
          </div>
        </div>
      </div>
    <div class="container">
      <div class="copyright" style="font-size:12px">
        &copy; {{new Date().getFullYear()}} First Consult <strong><span></span></strong>. All Rights Reserved
      </div>
      <div class="credits" style="font-size:12px">
        <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/flexstart-bootstrap-startup-template/ -->
        Made by <a href="https://resolution.studio/" class="resolutioon-link" style="    text-decoration: none;">Resolution Studio</a>
      </div>
    </div>
   
    </footer>
    <!-- End Footer -->
  </div>
</template>
<script>
import Vue from 'vue';
import { postFields } from "../../assets/js/service";
export default {
  data() {
    return {
      email: null,
    };
  },
  methods: {
     routeTo(route){
      
     
      this.$router.push(route);
    },
    subscribe() {
      let token = localStorage.getItem("token");
      if (this.email) {
        postFields("Subscribtions", { email: this.email }, token).then(
          (resp) => {
            this.email =null;
            Vue.$toast.success("Successful", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        )
        .catch(e=>{
          Vue.$toast.error(
      "connection refused", {
      position: "top-right",
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: "button",
      icon: true,
      rtl: false
    }
    );
        })
        ;
      }
    },
  },
};
</script>
<style>
.contactus-link{
  cursor: pointer;
}
.resolutioon-link{
  color: #626268;
}
.resolutioon-link:hover{
  color: white;
}
</style>