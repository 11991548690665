<template>
    <div>
        
        <NavBar v-if="!this.$route.path.startsWith('/home/') "></NavBar>
    
        <router-view></router-view>
        <Footer v-if="!this.$route.path.startsWith('/home/') "></Footer>
        
    </div>
    
</template>
<script>

import NavBar from './NavBarHeader.vue'
import Footer from './Footer.vue'


export default {
   components:{
       NavBar,
       Footer
    },
    mounted() {
    if (document.getElementById('myScript')) { return }
    let src = '../../assets/vendor/isotope-layout/isotope.pkgd.min.js'
    let script = document.createElement('script')
    script.setAttribute('src', src)
    script.setAttribute('type', 'text/javascript')
    script.setAttribute('id', 'myScript')
    document.head.appendChild(script)
},


beforeDestroy() {
    let el = document.getElementById('myScript')
    if (el) { el.remove() }
},
   methods: {

    }
}

</script>
  
<style>
.footer .footer-top .footer-links ul a {
    text-decoration: none;
}
.about .btn-read-more {
    text-decoration: none;
}
.hero .btn-get-started {
    text-decoration: none;
}
</style>