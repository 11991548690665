<template>
    <div>
          <header id="header" class="header fixed-top" :class="{ 'onScroll': !view.topOfPage}">
    <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
      
      <router-link to="/" class="logo d-flex align-items-center">
        <img src="@/assets/img/favicons.png" alt="">

     </router-link>

      <nav id="navbar" v-bind:class="{'navbar-mobile' : changeMobile}" class="navbar">
        <ul class="nav-search" v-bind:class="{'white-link' : this.$route.name == 'about_us'}">
        
 
          
          <li @click="changeMobileHam('close')"><router-link to="/our_work" v-bind:class="{'nav-link  active' : this.$route.name == 'our_work' ||this.$route.name == 'project' || this.$route.name =='components',  'nav-link ' : this.$route.name != 'our_work'}" @click="routeTo('our_work')">Our Work</router-link></li>
          <li @click="changeMobileHam('close')"><router-link to="/about_us" v-bind:class="{'nav-link  active' : this.$route.name == 'about_us' ,  'nav-link ' : this.$route.name != 'about_us'}" @click="routeTo('about_us')">About First Consult</router-link></li>
          <li @click="changeMobileHam('close')"> <router-link to="/knowledge" v-bind:class="{'nav-link  active' : this.$route.name == 'knowledge' || this.$route.name == 'knowledge_materials' ,  'nav-link ' : this.$route.name != 'knowledge'}" @click="routeTo('knowledge')">Knowledge</router-link></li>
          
          
          

          <li @click="changeMobileHam('close')"> <router-link to="/career" v-bind:class="{'nav-link  active' : this.$route.name == 'career' , 'nav-link  active' : this.$route.name == 'career-detail' , 'nav-link ' : this.$route.name != 'career'}" @click="routeTo('career')" >Opportunities</router-link></li>
          <li @click="routeTo('blog');changeMobileHam('close')"> <router-link to="/blog" @click="routeTo('blog')" v-bind:class="{'nav-link  active' : this.$route.name == 'blog' || this.$route.name == 'blog_description',  'nav-link ' : this.$route.name != 'blog'}" >News/Blog</router-link></li>
          
          <li v-if="dashboard"> <router-link to="/dashboard" v-bind:class="{'nav-link  active' : this.$route.name == 'dashboard' ,  'nav-link ' : this.$route.name != 'dashboard'}" @click="routeTo('dashboard')">Dashboard</router-link></li>
          


        </ul>
        <span href="index.html" class="logo d-flex align-items-center">


        </span>
        <i v-bind:class="{'bi mobile-nav-toggle bi-x' : changeMobile,'bi bi-list mobile-nav-toggle':!changeMobile}" @click="changeMobileHam"></i>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->
    </div>
    
</template>

<script>
import { getFieldById } from '../../assets/js/service';

export default {
    data () {
    return {
      changeMobile : false,
      newRoute : false,
      dashboard: false,
      view: {
        topOfPage: true
      }
    }
  },
  watch:{

  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  mounted() {
    getFieldById('configs','63480c6d924a1673b1a904b8',true,false).then(resp=>{
      console.log(resp.data);
      this.dashboard = resp.data.dashboard;
    })
  },
    beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    changeMobileHam(value){
      if(value == "close"){
        this.changeMobile =  true;
      }
      this.changeMobile =  !this.changeMobile;
    },
    
    routeTo(route){
       console.log('ssssssssssssssssssssss');
      this.changeMobile = false;
      
        console.log('ssssssssssssssssssssss');
        this.$store.commit("set", ["TagNews", null]);
        console.log(this.$store.TagNews)
      
      // this.$router.push('/'+route);
    },
    handleScroll(){
      if(window.pageYOffset>0){
        if(this.view.topOfPage) this.view.topOfPage = false
      } else {
        if(!this.view.topOfPage) this.view.topOfPage = true
      }
    }
  },
}
</script>
<style lang="scss">
   * {
  padding: 0;
  margin: 0;
}

body {

  header {
    position: fixed;
    width: 100%;
    height: 70px;
    background-color: transparent;
    display: flex;
    align-items: center;
    transition: all .2s ease-in-out;
    &.onScroll {
      box-shadow: 0 0 10px #aaa;
      background-color: #fff;
      
      ul li {
        color: #10b761;
      }
    }
 
  }
}
</style>